<template>
  <footer class="footer">
    <div class="container-fluid justify-content-between font-weight-bold text-center">
      <nav>
        <ul class="footer-menu">
          <li>
            <img src="./../../assets/images/logo/logo.png" class="img" alt="" style="height: 45px !important; width: auto !important; margin-right: 15px"/>
            <router-link to="">
<!--              {{ product_name }}-->
            </router-link>
          </li>
        </ul>
        <p class="m-0 mt-2" style="font-size: 10px;">{{$t('footer.recaptcha')}}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> {{$t('common.and')}}
          <a href="https://policies.google.com/terms">{{$t('footer.terms_service')}}</a> {{$t('footer.apply')}}.
        </p>

      </nav>
      <div class="copyright mt-4">
        &copy; {{ company_name }} {{ company_type }} {{ company_year }}<span v-if="year !== company_year"> - {{ year }}</span>. {{ $t('common.right_reserved') }}
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      product_name: process.env.VUE_APP_PRODUCT_NAME,
      company_name: process.env.VUE_APP_COMPANY_NAME,
      company_type: process.env.VUE_APP_COMPANY_TYPE,
      company_country: process.env.VUE_APP_COMPANY_COUNTRY,
      company_address: process.env.VUE_APP_COMPANY_ADDRESS,
      company_year: process.env.VUE_APP_COMPANY_YEAR,
    };
  },
};
</script>
<style></style>
