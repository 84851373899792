<template>
  <navbar :show-navbar="showNavbar" :transparent="false" id="nav" class="base-bg-clear">
    <div class="navbar-wrapper">
      <div class="navbar-toggle" :class="{ toggled: showNavbar }">
        <navbar-toggle-button @click="toggleNavbar" target="#nav">
        </navbar-toggle-button>
      </div>

<!--      <a class="navbar-brand" href="javascript:;">{{ $route.name }}</a>-->
        <router-link to="/dashboard">
            <img src="./../../assets/images/logo/logo_2.png" class="img" alt="" style="height: 45px !important; width: auto !important; margin-right: 15px"/>
        </router-link>
    </div>

    <template v-slot:navbar-menu>

      <ul class="navbar-nav">

          <li class="nav-item">
              <router-link to="/dashboard" class="nav-link">
                  <i class="now-ui-icons shopping_shop"></i>
                  <p><span>Dashboard</span></p>
              </router-link>
          </li>

<!--          <li class="nav-item">-->
<!--              <a href="javascript:void(0)" class="nav-link" @click="stripePortal()">-->
<!--                <i class="now-ui-icons shopping_cart-simple"></i>-->
<!--                <p><span>{{ $t('payments') }}</span></p>-->
<!--              </a>-->
<!--          </li>-->

<!--          <li class="nav-item">-->
<!--            <router-link to="/invoices" class="nav-link">-->
<!--              <i class="now-ui-icons files_paper"></i>-->
<!--              <p><span>{{$t('invoices.invoices')}}</span></p>-->
<!--            </router-link>-->
<!--          </li>-->

        <li class="nav-item">
          <router-link to="/invoices" class="nav-link">
            <i class="now-ui-icons files_paper"></i>
            <p><span>{{ $t('manage_subscriptions') }}</span></p>
          </router-link>
        </li>


          <drop-down tag="li" position="right" class="nav-item" icon="now-ui-icons files_box" :title="$t('document.bookkeeping')">
<!--            <router-link to="/documents/received" class="dropdown-item">-->
<!--              <i class="now-ui-icons arrows-1_cloud-download-93"></i> {{ $t('document.received') }}-->
<!--            </router-link>-->

<!--            <router-link to="/documents/uploaded" class="dropdown-item">-->
<!--              <i class="now-ui-icons arrows-1_cloud-upload-94"></i> {{ $t('document.sent') }}-->
<!--            </router-link>-->

<!--            <router-link to="/documents/to_sign" class="dropdown-item">-->
<!--              <font-awesome-icon icon="signature" style="color: #acacac" class="mr-2"></font-awesome-icon> {{ $t('document.to_sign') }}-->
<!--            </router-link>-->


<!--            <br>-->
<!--            <div class="dropdown-divider"></div>-->
<!--            <li class="text-center"> {{ $t('document.upload_new') }}</li>-->


<!--            <router-link to="/documents/new" class="dropdown-item">-->
<!--              <i class="now-ui-icons ui-1_simple-add"></i> {{ $t('document.upload_new') }}-->
<!--            </router-link>-->

            <router-link to="/documents/invoiceSent" class="dropdown-item">
              <i class="now-ui-icons shopping_tag-content"></i> {{ $tc('documents_folders.invoiceSent', 2) }}
            </router-link>

            <router-link to="/documents/invoiceReceived" class="dropdown-item">
              <i class="now-ui-icons shopping_basket"></i> {{ $tc('documents_folders.invoiceReceived', 2) }}
            </router-link>

            <router-link to="/documents/bankStatement" class="dropdown-item">
              <i class="now-ui-icons business_bank"></i> {{ $tc('documents_folders.bankStatement', 2) }}
            </router-link>

<!--            <router-link to="/documents/new_resolution" class="dropdown-item">-->
<!--              <i class="now-ui-icons files_paper"></i> {{ $t('documents_folders.resolutions') }}-->
<!--            </router-link>-->

<!--            <router-link to="/documents/new_company_house_docs" class="dropdown-item">-->
<!--              <i class="now-ui-icons business_bank"></i> {{ $t('documents_folders.companyHouse') }}-->
<!--            </router-link>-->

<!--            <router-link to="/documents/new_shareholders_docs" class="dropdown-item">-->
<!--              <i class="now-ui-icons business_badge"></i> {{ $t('documents_folders.shareholders') }}-->
<!--            </router-link>-->


          </drop-down>

<!--          <li class="nav-item">-->
<!--            <router-link to="/new_bank_connection/" class="nav-link">-->
<!--              <i class="now-ui-icons business_bank"></i>-->
<!--              <p><span>{{$t('bank.banks')}}</span></p>-->
<!--            </router-link>-->
<!--          </li>-->

<!--          <li class="nav-item">-->
<!--            <router-link to="/royalties" class="nav-link">-->
<!--              <i class="now-ui-icons business_chart-pie-36"></i>-->
<!--              <p><span>Royalties</span></p>-->
<!--            </router-link>-->
<!--          </li>-->


          <drop-down v-if="isManager" tag="li" position="right" class="nav-item" icon="now-ui-icons business_briefcase-24" title="Manager">
              <router-link to="/manager/users_stats" class="dropdown-item">
                  <i class="now-ui-icons business_chart-pie-36"></i> Customers
              </router-link>

              <router-link to="/manager/manage_subscriptions" class="dropdown-item">
                  <i class="now-ui-icons ui-1_simple-remove"></i> Failed Payments
              </router-link>

            <router-link to="/manager/revenue_report" class="dropdown-item">
              <i class="now-ui-icons business_money-coins"></i> Revenue Report
            </router-link>

            <router-link to="/manager/manage_products" class="dropdown-item">
              <i class="now-ui-icons location_compass-05"></i> Manage Subscriptions
            </router-link>

            <router-link to="/manager/manage_public_documents" class="dropdown-item">
              <i class="now-ui-icons files_box"></i> Public Documents
            </router-link>

            <router-link to="/manager/new_invoice" class="dropdown-item">
              <i class="now-ui-icons files_paper"></i> New Invoice
            </router-link>

            <router-link to="/manager/new_credit_note" class="dropdown-item">
              <i class="now-ui-icons files_single-copy-04"></i> New Credit Note
            </router-link>

            <router-link to="/manager/new_pro_forma" class="dropdown-item">
              <i class="now-ui-icons files_single-copy-04"></i> New Pro Forma
            </router-link>

            <router-link to="/manager/download_invoices" class="dropdown-item">
              <i class="now-ui-icons arrows-1_cloud-download-93"></i> Download invoices
            </router-link>

            <router-link to="/manager/download_pro_forma" class="dropdown-item">
              <i class="now-ui-icons arrows-1_cloud-download-93"></i> Download Pro Forma
            </router-link>

            <router-link to="/manager/unincorporated" class="dropdown-item">
              <i class="now-ui-icons business_bank"></i> Not incorporated
            </router-link>

            <router-link to="/manager/list_companies" class="dropdown-item">
              <i class="now-ui-icons business_bank"></i> Companies
            </router-link>

          </drop-down>

        <drop-down tag="li" position="right" class="nav-item" :title="$i18n.locale.toUpperCase()" :img="'/img/flags/' + $i18n.locale.toUpperCase() + '.png'">
          <a class="dropdown-item" v-for="lang in langs" v-bind:key="lang" @click="changeLocale(lang)"
             :set="langImg = '/img/flags/' + lang.toUpperCase() + '.png'">
            <img v-bind:src="langImg" style="padding-right: 4px; height: 24px !important; width:auto !important;"> {{ lang.toUpperCase() }}
          </a>
        </drop-down>



        <li class="nav-item">
          <a class="nav-link" @click="logout">
            <i class="now-ui-icons media-1_button-power"></i>
            <p><span class="d-lg-none d-md-block">{{ $t('login.logout') }}</span></p>
          </a>


        </li>
      </ul>
    </template>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "src/components";
import axios from "axios";
import {Role} from "src/role";
import Swal from "sweetalert2";
import {ElMessage} from "element-plus";

export default {
  components: {
    Navbar,
    NavbarToggleButton,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      langs: ['en', 'es', 'it'],
      isManager: this.$store.getters.getUserRoles.includes(Role.Manager)
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    // toggleSidebar() {
    //   this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    // },
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showNavbar = !this.showNavbar;
    },
    // hideSidebar() {
    //   this.$sidebar.displaySidebar(false);
    // },
    async recaptcha() {
        await this.$recaptchaLoaded();
        return await this.$recaptcha('registration');
    },
    async changeLocale(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('setLang', lang);
      if(this.$store.getters.isLoggedIn) {
          let token = await this.recaptcha();
          axios.patch(process.env.VUE_APP_SERVER_BASE_URL + "users/" + this.$store.getters.getUserId,
              {language: lang, captchaToken: token},
              {headers: {'Content-Type': 'application/merge-patch+json'}}
          ).then((response) => {}).catch((error) => {});
      }
    },
    logout() {
      axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'logout', '')
      .then(response => {
        this.$store.commit('logout');
        this.$router.push('/');
      }).catch(error => {
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else
          console.log("error in logout: " + error);
      });
    },
    stripePortal() {
        Swal.fire({
            title: this.$i18n.t("common.loading"),
            didOpen: () => {
                Swal.showLoading();
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions_stripe/customer_portal')
                    .then(response => {

                        window.location.href = response.data;
                    }).catch(error => {

                    if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                        if (error.response.data.message) {
                            ElMessage.error(this.$i18n.t("error.login_expired"));
                            this.$store.commit('logout');
                            this.$router.push('/login');
                        }
                    } else {
                        ElMessage.error(this.$i18n.t('err_loading_page'));
                        console.log(error);
                        Swal.close();
                    }
                }).finally(() => {});
            },
            allowOutsideClick: () => false
        });


  }
  },
};
</script>
<style></style>
